import React from "react";

import Link from "../components/link";
import Layout from "../components/layout";
import SignupForm from "../components/signupForm";
import SEO from "../components/seo";
import newsletters from "../newsletters";

const IndexPage = () => (
  <Layout>
    <SEO
      title={""}
      keywords={[
        "Electron",
        "newsletter",
        "apps",
        "job offers",
        "node libraries"
      ]}
    />

    <div className="p-4 pt-0 max-w-md m-auto text-center">
      <h2>
        A free, <span className="text-teal-dark">monthly</span> newsletter of
        the latest <span className="text-teal-dark">Electron</span> news,
        articles, projects and interviews.
      </h2>

      <SignupForm />

      <div className="pt-10 px-4 max-w-sm m-auto">
        <p>
          <b>Want to know what you can expect?</b>{" "}
          <Link
            to={`/archive/viewer?issue=${newsletters[0].issue}`}
            className="text-electron hover:text-teal-darker"
          >
            Read the latest issue
          </Link>
          .
        </p>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
