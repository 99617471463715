import React from "react";
import serialize from "form-serialize";

export default class SignupForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      messageType: "",
      showMessage: false
    };

    this.input = React.createRef();
  }

  clearMessage = e => {
    this.setState({
      message: "",
      messageType: "",
      showMessage: false
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    this.setState({
      message: "",
      messageType: "",
      showMessage: false
    });

    fetch(form.getAttribute("action"), {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: serialize(form)
    })
      .then(() => {
        this.setState({
          message: <><b>Thanks for signing up!</b><br /> Please click the confirmation link we sent you.</>,
          messageType: "success",
          showMessage: true
        });

        //this.input.current.value = "";
      })
      .catch(error =>
        this.setState({
          message: <>{error.message}</>,
          messageType: "error",
          showMessage: true
        })
      );
  };

  render() {
    const { message, showMessage, messageType } = this.state;

    return (
      <form
        action="https://www.cloudy.email/go/subscribe"
        className="w-full max-w-sm m-auto"
        onSubmit={this.handleSubmit}
      >
        {showMessage && <div className={`p-2 text-center ${messageType === 'error' ? 'text-red font-bold' : ''}`}>{message}</div>}
        <div className="flex items-center border-2 border-teal p-2 rounded-lg bg-white">
          <input
            className="m-0 appearance-none bg-transparent border-none w-full text-grey-darker mr-3 py-1 px-2 leading-tight focus:outline-none"
            type="email"
            id="email"
            name="email"
            placeholder="Your e-mail"
            aria-label="E-mail address"
            required
            onFocus={this.clearMessage}
            ref={this.input}
          />
          <input type="hidden" name="list" value="GYRwkpFd9QmBu1aLv01PLg" />
          <button type="submit" onClick={this.clearMessage}>
            Subscribe
          </button>
        </div>
        <small className="block text-center m-2 italic">
          We'll never share your email address and you can opt out at any time.
        </small>
      </form>
    );
  }
}
