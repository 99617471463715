const newsletters = [
  {
    issue: 4,
    date: "2019-06-07",
    title: "June 2019 newsletter",
    link: "https://www.cloudy.email/go/w/PnHQ892fY9M7BfD2fkp2Vkmg"
  },
  {
    issue: 3,
    date: "2019-04-05",
    title: "April 2019 newsletter",
    link: "https://www.cloudy.email/go/w/F3AEjshgfTkYI763N2UHzgYA"
  },
  {
    issue: 2,
    date: "2019-03-05",
    title: "March 2019 newsletter",
    link: "https://www.cloudy.email/go/w/e7636E0le8GLs424FfxPj8926A"
  },
  {
    issue: 1,
    date: "2019-02-04",
    title: "February 2019 newsletter",
    link: "https://www.cloudy.email/go/w/4smr21w8qTMRNavDJGveeg"
  }
];

export default newsletters;
